@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'
@import './quasar.variables'
@import '~shepherd.js/dist/css/shepherd'
// Icomoon
@font-face
  font-family: 'icomoon'
  src: url('fonts/icomoon.eot?hmplyq')
  src: url('fonts/icomoon.eot?hmplyq#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?hmplyq') format('truetype'), url('fonts/icomoon.woff?hmplyq') format('woff'), url('fonts/icomoon.svg?hmplyq#icomoon') format('svg')
  font-weight: normal
  font-style: normal
  font-display: block

[class^="icon-"], [class*=" icon-"]
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important
  speak: never
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
.widthXs
  width: 100vw
  position: relative
  left: -8px

.widthSm
  width: 100vw
  position: relative
  left: -15px

.customFontSize
  font-size: large

.icon-age .path1:before
  content: "\e900"
  color: rgb(255, 255, 255)

.icon-age .path2:before
  content: "\e901"
  margin-left: -1em
  color: rgb(0, 86, 114)

.icon-age .path3:before
  content: "\e902"
  margin-left: -1em
  color: rgb(33, 139, 174)

.icon-aide .path1:before
  content: "\e903"
  color: rgb(238, 241, 242)

.icon-aide .path2:before
  content: "\e904"
  margin-left: -1em
  color: rgb(123, 123, 123)

.icon-ajouteruncoupdecoeur:before
  content: "\e905"
  color: #707070

.icon-ajouterunprofil:before
  content: "\e906"
  color: #0c7a9f

.icon-aleatoire:before
  content: "\e907"

.icon-anniversaire:before
  content: "\e908"
  color: #0c7a9f

.icon-auteurs:before
  content: "\e909"
  color: #0c7a9f

.icon-calendrier:before
  content: "\e90a"

.icon-carton:before
  content: "\e90b"
  color: #0c7a9f

.icon-cloud:before
  content: "\e90c"
  color: #0c7a9f

.icon-codebarre:before
  content: "\e90d"
  color: #717171

.icon-coupdecoeur:before
  content: "\e90e"

.icon-crayon:before
  content: "\e90f"

.icon-cubes .path1:before
  content: "\e910"
  color: rgb(236, 206, 30)

.icon-cubes .path2:before
  content: "\e911"
  margin-left: -1em
  color: rgb(204, 179, 2)

.icon-cubes .path3:before
  content: "\e912"
  margin-left: -1em
  color: rgb(183, 156, 11)

.icon-derouler:before
  content: "\e913"
  color: #0c7a9f

.icon-effacer:before
  content: "\e914"
  color: #0c7a9f

.icon-facebook:before
  content: "\e915"
  color: #0c7a9f

.icon-filtres:before
  content: "\e916"
  color: #0c7a9f

.icon-illustrateurs:before
  content: "\e917"
  color: #0c7a9f

.icon-imagedeprofil:before
  content: "\e918"
  color: #0c7a9f

.icon-instagram:before
  content: "\e919"
  color: #0c7a9f

.icon-jeu:before
  content: "\e91a"
  color: #0c7a9f

.icon-joueurs .path1:before
  content: "\e91b"
  color: rgb(255, 255, 255)

.icon-joueurs .path2:before
  content: "\e91c"
  margin-left: -1em
  color: rgb(33, 139, 174)

.icon-joueurs .path3:before
  content: "\e91d"
  margin-left: -1em
  color: rgb(0, 86, 114)

.icon-joueurs .path4:before
  content: "\e91e"
  margin-left: -1em
  color: rgb(0, 86, 114)

.icon-joueurs .path5:before
  content: "\e91f"
  margin-left: -1em
  color: rgb(0, 86, 114)

.icon-lesplusdujeu .path1:before
  content: "\e920"
  color: rgb(255, 255, 255)

.icon-lesplusdujeu .path2:before
  content: "\e921"
  margin-left: -1em
  color: rgb(12, 122, 159)

.icon-lesplusdujeu .path3:before
  content: "\e922"
  margin-left: -1em
  color: rgb(255, 255, 255)

.icon-lesplusdujeu .path4:before
  content: "\e923"
  margin-left: -1em
  color: rgb(255, 255, 255)

.icon-lesplusdujeu .path5:before
  content: "\e924"
  margin-left: -1em
  color: rgb(255, 255, 255)

.icon-lesplusdujeu .path6:before
  content: "\e925"
  margin-left: -1em
  color: rgb(255, 255, 255)

.icon-linkedin .path1:before
  content: "\e926"
  color: rgb(12, 122, 159)

.icon-linkedin .path2:before
  content: "\e927"
  margin-left: -1em
  color: rgb(12, 122, 159)

.icon-linkedin .path3:before
  content: "\e928"
  margin-left: -1em
  color: rgb(74, 134, 197)

.icon-linkedin .path4:before
  content: "\e929"
  margin-left: -1em
  color: rgb(55, 124, 165)

.icon-linkedin .path5:before
  content: "\e92a"
  margin-left: -1em
  color: rgb(79, 172, 214)

.icon-linkedin .path6:before
  content: "\e92b"
  margin-left: -1em
  color: rgb(255, 255, 255)

.icon-news:before
  content: "\e92c"

.icon-panieraveccontenu .path1:before
  content: "\e92d"
  color: rgb(12, 122, 159)

.icon-panieraveccontenu .path2:before
  content: "\e92e"
  margin-left: -1em
  color: rgb(12, 122, 159)

.icon-panieraveccontenu .path3:before
  content: "\e92f"
  margin-left: -1em

.icon-panieraveccontenu .path4:before
  content: "\e930"
  margin-left: -1em

.icon-panieraveccontenu .path5:before
  content: "\e931"
  margin-left: -1em

.icon-paniervide:before
  content: "\e932"

.icon-parametres:before
  content: "\e933"

.icon-pictotribuo .path1:before
  content: "\e934"

.icon-pictotribuo .path2:before
  content: "\e935"
  margin-left: -1em

.icon-pictotribuo .path3:before
  content: "\e936"
  margin-left: -1em

.icon-pictotribuo .path4:before
  content: "\e937"
  margin-left: -1em

.icon-pictotribuo .path5:before
  content: "\e938"
  margin-left: -1em

.icon-pictotribuo .path6:before
  content: "\e939"
  margin-left: -1em

.icon-pictotribuo .path7:before
  content: "\e93a"
  margin-left: -1em

.icon-pictotribuo .path8:before
  content: "\e93b"
  margin-left: -1em

.icon-plume:before
  content: "\e93c"
  color: #0c7a9f

.icon-precedent:before
  content: "\e93d"
  color: #0c7a9f

.icon-profil:before
  content: "\e93e"

.icon-retour:before
  content: "\e93f"
  color: #0c7a9f

.icon-sav:before
  content: "\e940"
  color: #0c7a9f

.icon-skills:before
  content: "\e941"
  color: #0c7a9f

.icon-suivant:before
  content: "\e942"
  color: #0c7a9f

.icon-supprimerunprofil:before
  content: "\e943"
  color: #0c7a9f

.icon-tchatcommercial:before
  content: "\e944"
  color: #0c7a9f

.icon-temps .path1:before
  content: "\e945"
  color: rgb(255, 255, 255)

.icon-temps .path2:before
  content: "\e946"
  margin-left: -1em
  color: rgb(0, 86, 114)

.icon-temps .path3:before
  content: "\e947"
  margin-left: -1em
  color: rgb(0, 86, 114)

.icon-temps .path4:before
  content: "\e948"
  margin-left: -1em
  color: rgb(0, 86, 114)

.icon-temps .path5:before
  content: "\e949"
  margin-left: -1em
  color: rgb(31, 137, 172)

.icon-temps .path6:before
  content: "\e94a"
  margin-left: -1em
  color: rgb(31, 137, 172)

.icon-temps .path7:before
  content: "\e94b"
  margin-left: -1em
  color: rgb(31, 137, 172)

.icon-tirelire:before
  content: "\e94c"
  color: #0c7a9f

.icon-twitter:before
  content: "\e955"
  color: #0c7a9f

.icon-vousavezcollecte .path1:before
  content: "\e956"
  color: rgb(236, 206, 30)

.icon-vousavezcollecte .path2:before
  content: "\e957"
  margin-left: -1em
  color: rgb(183, 156, 11)

.icon-vousavezcollecte .path3:before
  content: "\e958"
  margin-left: -1em
  color: rgb(204, 179, 2)

.icon-vousavezcollecte .path4:before
  content: "\e959"
  margin-left: -1em
  color: rgb(204, 179, 2)

.icon-vousavezcollecte .path5:before
  content: "\e95a"
  margin-left: -1em
  color: rgb(236, 206, 30)

.icon-vousavezcollecte .path6:before
  content: "\e95b"
  margin-left: -1em
  color: rgb(183, 156, 11)

.icon-vousavezcollecte .path7:before
  content: "\e95c"
  margin-left: -1em
  color: rgb(236, 206, 30)

.icon-vousavezcollecte .path8:before
  content: "\e95d"
  margin-left: -1em
  color: rgb(183, 156, 11)

.icon-vousavezcollecte .path9:before
  content: "\e95e"
  margin-left: -1em
  color: rgb(204, 179, 2)

.icon-vousavezcollecteequipe .path1:before
  content: "\e95f"
  color: rgb(236, 206, 30)

.icon-vousavezcollecteequipe .path2:before
  content: "\e960"
  margin-left: -1em
  color: rgb(204, 179, 2)

.icon-vousavezcollecteequipe .path3:before
  content: "\e961"
  margin-left: -1em
  color: rgb(183, 156, 11)

.icon-vousavezcollecteequipe .path4:before
  content: "\e962"
  margin-left: -1em
  color: rgb(236, 206, 30)

.icon-vousavezcollecteequipe .path5:before
  content: "\e963"
  margin-left: -1em
  color: rgb(183, 156, 11)

.icon-vousavezcollecteequipe .path6:before
  content: "\e964"
  margin-left: -1em
  color: rgb(204, 179, 2)

.icon-vousavezcollecteequipe .path7:before
  content: "\e965"
  margin-left: -1em
  color: rgb(183, 156, 11)

.icon-vousavezcollecteequipe .path8:before
  content: "\e966"
  margin-left: -1em
  color: rgb(236, 206, 30)

.icon-vousavezcollecteequipe .path9:before
  content: "\e967"
  margin-left: -1em
  color: rgb(204, 179, 2)

.icon-vousavezcollecteequipe .path10:before
  content: "\e968"
  margin-left: -1em
  color: rgb(236, 206, 30)

.icon-vousavezcollecteequipe .path11:before
  content: "\e969"
  margin-left: -1em
  color: rgb(204, 179, 2)

.icon-vousavezcollecteequipe .path12:before
  content: "\e96a"
  margin-left: -1em
  color: rgb(183, 156, 11)

.icon-vousavezcollecteequipe .path13:before
  content: "\e96b"
  margin-left: -1em
  color: rgb(204, 179, 2)

.icon-vousavezcollecteequipe .path14:before
  content: "\e96c"
  margin-left: -1em
  color: rgb(236, 206, 30)

.icon-vousavezcollecteequipe .path15:before
  content: "\e96d"
  margin-left: -1em
  color: rgb(183, 156, 11)

.icon-vousavezcollecteequipe .path16:before
  content: "\e96e"
  margin-left: -1em
  color: rgb(204, 179, 2)

.icon-vousavezcollecteequipe .path17:before
  content: "\e96f"
  margin-left: -1em
  color: rgb(236, 206, 30)

.icon-vousavezcollecteequipe .path18:before
  content: "\e970"
  margin-left: -1em
  color: rgb(183, 156, 11)

.icon-vuebloc:before
  content: "\e971"
  color: #0c7a9f

.icon-vueligne:before
  content: "\e972"
  color: #0c7a9f

.icon-youtube:before
  content: "\e973"
  color: #0c7a9f

// Fonts
@font-face
  font-family: TexGyreAdventor
  src: url(~src/css/fonts/Texgyreadventor.otf)

@font-face
  font-family: Comfortaa
  src: url(~src/css/fonts/Comfortaa.ttf)

// app global css in Sass form

body
  font-family: Comfortaa
  scroll-behavior: smooth

.cursor-pointer
  cursor: pointer

.hover-opacity
  transition: all 1s
.hover-opacity:hover
  opacity: 0.7

@for $i from 1 through 100
  .border-radius-#{$i}
    border-radius: #{$i}px !important
  .border-radius-top-#{$i}
    border-top-left-radius: #{$i}px !important
    border-top-right-radius: #{$i}px !important
  .border-radius-bottom-#{$i}
    border-bottom-left-radius: #{$i}px !important
    border-bottom-right-radius: #{$i}px !important
  .border-radius-left-#{$i}
    border-top-left-radius: #{$i}px !important
    border-bottom-left-radius: #{$i}px !important
  .border-radius-right-#{$i}
    border-top-right-radius: #{$i}px !important
    border-bottom-right-radius: #{$i}px !important
  .padding-side-#{$i}
    padding-left: #{$i}rem
    padding-right: #{$i}rem
  .margin-side-#{$i}
    padding-left: #{$i}rem
    padding-right: #{$i}rem

.img-round
  border-radius: 50%

// Override some question components
.order-expansion.q-expansion-item
  .q-item__section
    display: none

.q-tabs-scroller, .q-tab
  flex: 1 1 auto

.blurred
  filter: blur(0.2rem) grayscale(80%)

.input-tribuo.medium
  .q-field__native
    font-family: CenturyGothic

.input-tribuo
  .q-field__inner
    .q-field__control, .q-field__bottom
      border-radius: 10px
      padding-left: 1rem
      padding-right: 1rem

.gothic-bold
  font-family: Comfortaa !important

.gothic-medium
  font-family: TexGyreAdventor !important

.light-btn
  .q-btn__content
    .block
      font-family: TexGyreAdventor

.barcode
  width: 10rem

.text-color-primary
  color: $primary
  font-family: TexGyreAdventor
.text-color-primary-bold
  color: $primary
  font-family: Comfortaa
.text-beige
  color: #B7B7B7

.shadow-blue
  box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16) !important
  -webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16) !important
  -moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16) !important
  background-color: $primary !important

.shepherd-arrow::before
  @extend .shadow-blue
.shepherd-theme-custom
  @extend .shadow-blue
  &.shepherd-element[data-popper-placement="bottom"]
    transform: translateY(20px)
  &.shepherd-element[data-popper-placement="top"]
    transform: translateY(-14px)
  .shepherd-title
    flex: 1 1 auto
    font-size: 1.25rem
    font-weight: 600
    color: white
  .shepherd-text
    color: white
  .shepherd-footer
    justify-content: space-between
  header
    background-color: #0C7A9F !important
    padding-bottom: 0 !important
  button
    background: none !important
    padding: 5px
  p, span
    color: white !important
    font-family: TexGyreAdventor
  .shepherd-button-close
    position: absolute
    top: 0
    right: 0
    padding-right: 10px
    padding-top: 10px
    i
      font-size: 35px
.select-no-arrow
  .q-field__append > .q-select__dropdown-icon
    display: none

.q-page-container .q-layout-padding
  max-width: $breakpoint-sm-max
  margin: 0 auto

.q-btn.text-weight-bold span.q-btn__content
  font-weight: bold

.q-tabs--horizontal .q-tabs__arrow
  background: white

.text-h1, .text-h2, .text-h3, .text-h4, .text-h2, .text-h1
  line-height: 1.1

p a, p a:visited, p a:hover
    color: $primary

.hover-grey
  &:hover
    background-color: $grey-3
    opacity: 0.9

.q-btn
  font-weight: bold

.rounded-borders-top
  border-radius: $generic-border-radius $generic-border-radius 0 0

.q-tab__label
  font-weight: 800

.badge-small-round-borders
  border-radius: 5px

.z-top-low
  z-index: 100

.product-subinfos
  span
      text-transform: capitalize
  span:not(:first-child):before
      content: ' - '

.product-2lines
  font-size: 0.8rem

.pointer
  cursor: pointer

.check-title
  font-size: 1.2em
  font-weight: bold

.no-pic-placeholder
  opacity: 0.5
  .q-img__image
    background-size:50% !important

.quantity-dot
  font-weight: bold
  line-height: 1rem
  text-align: center
  border-radius: 100%
  width: 1rem
  height: 1rem
  font-size: 0.55rem
  &.bigger
    width: 1.8rem
    height: 1.8rem
    line-height: 1.8rem
    font-size: 1rem
  &.top-padded
    right: -0.3rem
    top: -0.3rem

.btn-qtemax
  font-size:0.5rem !important
  display:inline-block
  width: 24px
  height: 24px
  line-height:1
  i
    display: none

// Inté tarte au citron
body #tarteaucitronRoot div#tarteaucitronAlertBig
  box-shadow: 0 0 9px #000 !important
  background: $primary

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine, #tarteaucitronInfo
  background: $primary !important

div#tarteaucitronInfo
  border-color: $primary !important

#tarteaucitronRoot button
  border-radius:15px !important
  height: 2.6rem
  padding-top: 4px
  line-height: 2rem !important
  &#tarteaucitronClosePanel, &.tarteaucitronIsSelected, &.tarteaucitronAllow
    background: $accent !important

html body #tarteaucitronRoot #tarteaucitronServices .tarteaucitronTitle button
  background: $accent !important
  border-radius: 15px 15px 0 0 !important
  @media screen and (max-width: 767px)
    margin-bottom: 0 !important

#tarteaucitronClosePanel
  top: 0.2rem

div#tarteaucitronServices
  margin-top: 4rem !important
