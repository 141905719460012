// Quasar Sass (& SCSS) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary   : #0C7A9F
$secondary : #CCB303
$accent    : #005672

$dark      : #1D1D1D
$red       : #D52A2A

$positive  : #21BA45
$negative  : #C10015
$info      : #31CCEC
$warning   : #F2C037

$orange   : #FCB200

$tileproduct: #EDF1F3
.bg-tileproduct
  background-color: $tileproduct

$generic-border-radius: 15px

$h1:        (size: 1.25rem,     line-height: 1.30rem, weight: 600) !default
$h2:        (size: 1.2rem,  line-height: 1.25rem, weight: 600) !default
$h3:        (size: 1.1rem,     line-height: 1.15rem, letter-spacing: normal,    weight: 400) !default
$h4:        (size: 1rem, line-height: 1.05rem,  weight: 400) !default
$h5:        (size: 0.8rem,   line-height: 0.85rem,     letter-spacing: normal,    weight: 400) !default
$h6:        (size: 0.7rem,  line-height: 0.75rem,   weight: 500) !default
